.dl-modal {
  & > .ant-modal-content {
    background-color: var(--dark-blue-3);
    & > .ant-modal-close {
      color: var(--grey-1);
    }
    & > .ant-modal-header {
      border-bottom: 1px solid rgba(58, 63, 77, 0.8);
      background-color: inherit;
      & > .ant-modal-title {
        font-family: 'Questrial', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 28px;
        color: var(--grey-1);
      }
    }
    & > .ant-modal-body {
      & > .dl-modal__content-image {
        margin-bottom: 10px;
        & > img {
          width: 100%;
          height: auto;
          max-height: 160px;
        }
      }
    }
    & > .ant-modal-footer {
      border-top: 0;
      display: flex;
      justify-content: flex-end;
    }
  }
}