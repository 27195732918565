.dl-input {
  .ant-input-affix-wrapper {
    background-color: var(--dark-blue-4);
    border-color: var(--grey-4);
    &:not(.ant-input-affix-wrapper-disabled):hover, &:not(.ant-input-affix-wrapper-disabled):focus {
      border-color: var(--cyan-4)
    }
  }

  .ant-input {
    color: var(--grey-2);
    background-color: var(--dark-blue-4);
  }

  .ant-input-prefix {
    color: var(--grey-2);
  }

  .ant-input-suffix .anticon {
    color: var(--grey-2);
  }

  .ant-input-affix-wrapper-disabled {
    .ant-input {
      color: var(--grey-1);
    }

    .ant-input-prefix {
      color: var(--grey-1);
    }

    .ant-input-suffix .anticon {
      color: var(--grey-1);
    }
  }

}
