.dl-card-data {
  width: 100%;
  background-color: var(--dark-blue-3);
  padding-top: 20px;
  padding-bottom: 20px;
  border-radius: 4px;
  margin-bottom: 16px;

  &__title {
    padding-bottom: 16px;
    padding-left: 32px;
    padding-right: 32px;
    border-bottom: 1px solid #03021D;
    h4 {
      color: var(--grey-1);
      font-family: 'Questrial', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
    }
  }

  &__body {
    padding-top: 20px;
    padding-left: 32px;
    padding-right: 32px;

    &-option-title {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      &-content {
        width: auto;
        display: flex;
        align-items: center;
        h5 {
          margin: 0 8px 0 0;
          color: var(--grey-2);
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
        }
        & > .anticon {
          color: var(--grey-3);
          font-size: 16px;
        }
        &:hover {
          & > .anticon {
            color: var(--cyan-4);
          }
        }
      }
    }

    &-option-content {
      width: 100%;
      margin-top: 20px;
      &-radio {
        width: 100%;
      }
      & > .ant-radio-group {
        width: 100%;
        display: flex;
        justify-content: space-between;
      }
      & > .ant-slider {
        margin-left: 8px;
        margin-right: 8px;
      }
    }
  }
}