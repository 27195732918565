.dl-nav {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 24px;
  background: #0f102d;
  border-radius: 4px;
  height: 46px;
  padding: 0 16px;
  margin-bottom: 16px;

  &-sticky {
    &-spacer {

    }
    @media (max-width: 768px) {
      position: fixed;
      top: 0;
      left: 0;
      border-radius: 0;
      z-index: 10;
      width: 100%;
      box-shadow: inset 0px -1px 0px rgba(178, 190, 223, 0.3);
    }
  }

  .menu-container {
    display: flex;
    align-items: center;
    @media (max-width: 768px) {
      width: 100%;
    }
  }
  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background: #0050c7 !important;
  }
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled){
    background: #0f102d !important;
    color: #1a94ff !important;
  }

  .deep-apps-select {
    border-right: 1px solid #585B64;
    min-width: 200px;
    @media (max-width: 768px) {
      border: none;
      width: 100%;
    }
    .ant-select-selector {
      border: none !important;
      box-shadow: none !important;
    }
  }
  .ant-select-selector {
    border: 1px solid #585b64 !important;
    height: 32px !important;
    background: #0f102d !important;
    border-radius: 2px;
    .ant-select-selection-item {
      font-weight: 500;
      font-size: 14px;
      line-height: 22px !important;
      display: flex;
      align-items: center;
      color: #f1f5ff;
    }
  }
  &__right-text {
    text-align: right;
    @media (max-width: 768px) {
      display: none;
    }
    span {
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      color: #F1F5FF;
      &:first-child {
        margin-right: 16px;
      }
    }
  }
  .ant-select-arrow {
    color: #f1f5ff;
  }
}