@import '~antd/dist/antd.css';

// Fonts
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Questrial&display=swap');

// Components
@import 'components/button/styles';
@import 'components/checkbox/styles';
@import 'components/slider/styles';
@import 'components/radio/styles';
@import 'components/switch/styles';
@import 'components/modal/styles';
@import 'components/input/styles';
@import 'components/select/styles';
@import 'components/tooltip/styles';
@import 'components/header/styles';
@import 'components/hero/styles';
@import 'components/bottom-bar/styles';
@import 'components/footer/styles';
@import 'components/mobile-drawer/styles';

// Cards
@import 'cards/navigation/styles';
@import 'cards/data/styles';
@import 'cards/features/styles';
@import 'cards/account/styles';
@import 'cards/footer/styles';

* {
  font-family: 'Montserrat', sans-serif;
}

:root {
  --grey-1: #FFFFFF;
  --grey-2: #F1F5FF;
  --grey-3: #B2BEDF;
  --grey-4: #585B64;
  --grey-5: #33363E;
  --grey-6: #1E2126;

  --cyan-1: #F4F9FE;
  --cyan-2: #E5F2FF;
  --cyan-3: #CDE6FF;
  --cyan-4: #1A94FF;
  --cyan-5: #0050C7;
  --cyan-6: #003D98;

  --dark-blue-1: #1F168F;
  --dark-blue-2: #070156;
  --dark-blue-3: #0F102D;
  --dark-blue-4: #040020;

  --gradient-1-1: var(--dark-blue-3);
  --gradient-1-2: #173E81;
  --gradient-2-1: #173E81;
  --gradient-2-2: var(--dark-blue-3);

  --primary-color: var(--cyan-5);
  --primary-color-hover: var(--cyan-3);
  --primary-color-disabled: var(--grey-4);
  --primary-color-ghost: var(--cyan-5);
  --primary-color-ghost-disabled: var(--grey-2);

  --secondary-color: var(--grey-1);
  --secondary-color-hover: var(--dark-blue-3);
  --secondary-color-disabled: var(--grey-4);
  --secondary-color-ghost: var(--grey-1);
  --secondary-color-ghost-disabled: var(--grey-4);
}

body {
  overflow-x: hidden;
}

.dl-app {
  height: 100%;
  padding: 0 16px;
  background: #040020 linear-gradient(346.38deg, rgba(4, 0, 32, 0) 29.42%, rgba(13, 31, 80, 0.492297) 65.27%, #173E81 82.81%, #4889D7 100%);
}