.dl-header {
  display: flex;
  justify-content: space-between;
  padding: 16px 80px;
  margin-bottom: 48px;

  @media (max-width: 768px) {
    margin: 0;
    padding: 12px 0;
  }
  &__logo > img {
    max-width: 200px;
  }
  &__right {
    display: flex;
    align-items: center;
    @media (max-width: 768px) {
      display: none;
    }
    & > a {
      color: var(--grey-1);
      text-decoration: none;
      font-family: 'Montserrat', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      margin-right: 24px
    }
    & > .dl-button > .ant-btn {
      background-color: var(--dark-blue-4);
      border: 1px solid var(--dark-blue-4);
    }
  }
}
