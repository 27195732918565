.mobile-drawer-container {
  display: none;
  @media (max-width: 768px) {
    display: block;
    position: absolute;
    right: -8px;
  }
}
.ant-drawer-header {
  .ant-drawer-header-title {
    order: 2;
    justify-content: end;
    .ant-drawer-close {
     margin: 0;
     padding: 0;
    }
  }
}
.ant-drawer-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  a {
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: #040020;
    margin-bottom: 16px;
  }

  button {
    display: flex;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: #FFFFFF;
    justify-content: center;
    align-items: center;
    padding: 5px 16px;
    width: 228px;
    height: 32px;
    background: #040020;
    border-radius: 32px;
  }
}