.dl-hero {
  width: 100%;
  margin-bottom: 32px;
  margin-left: 80px;
  @media (max-width: 768px) {
    margin-left: 0;
  }

  &__container {
    max-width: 623px;
    width: 100%;
    text-align: left;
    margin-left: 80px;
    @media (max-width: 768px) {
      margin: 0;
      max-width: 288px;
    }

    &-image {
      margin: 14px 0 0;
      @media (max-width: 768px) {
        max-width: 260px;
        img {
          width: 100%;
        }
      }
    }
    &-description {
      font-weight: 200;
      font-size: 22px;
      line-height: 32px;
      color: rgb(255, 255, 255);
      margin-top: 9px;
      margin-right: 0px;
      margin-bottom: 12px;
    }
    &-contact {
      font-weight: 600;
      font-size: 14px;
      line-height: 22px;
      text-align: center;
      color: #040020;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 4px 15px;
      background: #ffffff;
      border-radius: 32px;
      border: 1px solid #ffffff;
      max-width: 141px;
    }
  }
}