.dl-checkbox {
  $self: &;
  .ant-checkbox-wrapper > .ant-checkbox {
    & > .ant-checkbox-inner {
      border: 2px solid var(--primary-color-hover);
    }

    &:hover {
      & > .ant-checkbox-inner {
        border: 2px solid var(--primary-color)
      }
    }

    &.ant-checkbox-indeterminate {
      & > .ant-checkbox-inner {
        border: 1px solid var(--primary-color);

        &:after {
          background-color: var(--primary-color);
        }
      }
    }

    &.ant-checkbox-checked {
      & > .ant-checkbox-inner {
        background-color: var(--primary-color);
        border: 2px solid var(--primary-color);

        &:after {
          top: 45%;
          left: 19.5%;
        }
      }

      &:after {
        border: 2px solid var(--primary-color)
      }
    }

    &.ant-checkbox-disabled {
      & > .ant-checkbox-inner {
        opacity: 0.64;
        background-color: var(--grey-1);
        border: 2px solid var(--primary-color-disabled);

        &:after {
          top: 45%;
          left: 19.5%;
        }
      }
    }
  }

  span &__label {
    color: var(--grey-1);
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
  }

  .ant-checkbox-wrapper-disabled {
    span #{ $self }__label {
      opacity: 0.64;
    }
  }
}