.dl-slider {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  //align-items: center;
  & > .ant-slider {
    flex: 1;
    & > .ant-slider-track {
      background-color: var(--grey-3);
    }
    & > .ant-slider-handle {
      border: 2px solid var(--grey-3);
      &:hover {
        border-color: var(--primary-color);
      }
      &:focus {
        border-color: var(--primary-color);
        box-shadow: 0 0 0 5px rgba(26, 148, 255, 0.3);
      }
    }
    & > .ant-slider-step {
      & > .ant-slider-dot {
        border: 2px solid var(--grey-3);
        &.ant-slider-dot-active {
          border: 2px solid var(--primary-color);
        }
      }
    }
    & > .ant-slider-mark {
      & > .ant-slider-mark-text {
        color: var(--grey-1);
        font-size: 14px;
        line-height: 22px;
        font-weight: 400;
      }
    }
    &.ant-slider-disabled {
      & > .ant-slider-track {
        background-color: var(--primary-color-disabled) !important;
      }
      & > .ant-slider-handle {
        border-color: var(--primary-color-disabled) !important;
      }
    }
  }

  &__icon {
    align-self: center;
    margin-left: 16px;
    font-size: 16px;
    color: var(--secondary-color);
  }
  &__icon-marks {
    margin-top: 4px;
    margin-left: 16px;
    font-size: 16px;
    color: var(--secondary-color);
  }
  //& > .anticon {
  //
  //}
}