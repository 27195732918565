.dl-footer {
  background-color: var(--dark-blue-4);
  &__main {
    padding-top: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &-title > h1 {
      color: var(--grey-1);
      font-family: 'Questrial', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 38px;
      margin-bottom: 24px;
    }
    &-image {
      align-self: center;
      & > img {
        max-height: 178px;
        @media only screen and (max-width: 768px) {
          width: 100%;
        }
      }
    }
  }
  &__bottom-bar {
    display: flex;
    justify-content: space-between;
    color: var(--grey-1);
    padding: 12px 0;
    box-shadow: inset 0 1px 0 rgba(178, 190, 223, 0.3);
    &-copyrights > h5 {
      color: var(--grey-1);
      font-family: 'Montserrat', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
    }
    &-right {
      display: flex;
      justify-content: center;
      &-privacy > a {
        color: #FFFFFF;
        text-decoration: underline;
        font-family: 'Montserrat', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
      }
      &-separator {
        width: 1px;
        max-height: 32px;
        opacity: .3;
        background-color: var(--grey-3);
        margin: 0 20px;
      }
      &-icons > a {
        color: var(--grey-1);
      }
      &-icons > a:first-child {
        margin-right: 24px;
      }
    }
  }
  @media only screen and (max-width: 672px) {
    &__main {
      padding: 0 17px;
    }
    &__bottom-bar {
      flex-direction: column;
      align-items: center;
      &-right {
        flex-direction: column;
        align-items: center;
        padding-top: 12px;
        &-icons {
          padding-top: 12px;
        }
      }
    }
  }
  @media only screen and (min-width: 672px) {
    &__bottom-bar {
      padding: 12px 32px;
    }
  }
  @media only screen and (min-width: 1312px) {
    &__bottom-bar {
      padding: 12px 96px;
    }
  }
}
.dl-footer__main-button > .dl-button > .ant-btn {
  min-width: 160px;
}