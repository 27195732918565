.dl-card-features {
  width: 100%;
  background-color: var(--dark-blue-3);
  padding-top: 20px;
  @media (max-width: 768px) {
    padding-bottom: 0;
  }
  padding-bottom: 20px;
  border-radius: 4px;
  margin-bottom: 16px;

  &__title {
    padding-bottom: 16px;
    padding-left: 32px;
    padding-right: 32px;
    border-bottom: 1px solid #03021D;
    h4 {
      color: var(--grey-1);
      font-family: 'Questrial', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
    }
    &-status {
      display: flex;
      margin-top: 6px;
      &-icon {
        font-family: 'Montserrat', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        color: var(--grey-2);
        margin-right: 24px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        & > #active {
          width: 8px;
          height: 8px;
          border-radius: 8px;
          margin-right: 8px;
          background: var(--cyan-4);
        }

        & > #inactive {
          width: 8px;
          height: 8px;
          border-radius: 8px;
          margin-right: 8px;
          background: #494C59;
        }
      }
    }
  }

  &__body {
    & > div:nth-child(2) {
      @media (max-width: 768px) {
        padding-top: 20px;
      }
    }
    padding-top: 20px;
    padding-left: 32px;
    padding-right: 32px;
    @media (max-width: 768px) {
      flex-direction: column;
      padding: 16px;
    }
    h5 {
      font-family: 'Questrial', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      color: #FFFFFF;
    }
    &-footnote {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      color: #B2BEDF;
    }
    &-features {
      @media (min-width: 769px) {
        margin-top: 24px;
      }
      @media (max-width: 768px) {
        flex-direction: column;
        .ant-col {
          max-width: 100%;
          width: 100%;
        }
      }
      &-container {
        max-width: 288px;
        @media (min-width: 769px) {
          min-height: 378px;
        }
        @media (max-width: 768px) {
          max-width: 100%;
          width: 100%;
          padding-top: 24px;
          //min-height: 310px;
        }
      }
      &-title {
        font-family: 'Montserrat', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        color: #F1F5FF;
        margin-right: 8px;
        &-container {
          margin-bottom: 20px;
          & > .anticon {
            color: var(--grey-3);
            font-size: 16px;
          }
          &:hover {
            & > .anticon {
              color: var(--cyan-4);
            }
          }
          display: inline-flex;
          align-items: center;
        }
      }
      &-info {
        @media (max-width: 768px) {
          position: inherit;
        }
        color: var(--cyan-4);
        font-size: 20px;
        margin-top: 20px;
        position: absolute;
        bottom: 0;
        span {
          max-width: 208px;
        }
      }

      &-poc {
        &-switch {
          display: flex;
          justify-content: space-between;
          margin-bottom: 16px;
          & > span {
            font-family: 'Montserrat', sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            color: var(--grey-2);
          }
        }
      }
      &-network {
        &-switch {
          display: flex;
          justify-content: space-between;
          margin-bottom: 8px;
          & > span {
            font-family: 'Montserrat', sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            color: var(--grey-2);
          }
        }
      }
    }
  }

  &__modal {
    &-text {
      font-family: 'Montserrat', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      color: var(--grey-3);
    }
  }
}