.dl-switch {
  .ant-switch {
    .ant-switch-inner {
      color: var(--dark-blue-3);
    }
  }

  .ant-switch-disabled {
    background: var(--grey-3);

    .ant-switch-inner {
      color: var(--dark-blue-3);
    }
  }

  .ant-switch-loading, .ant-switch-disabled {
    opacity: .6;
  }

  .ant-switch-checked {
    background: var(--primary-color);

    .ant-switch-inner {
      color: var(--grey-3);
    }
  }
}