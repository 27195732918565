.dl-select {
  & > .ant-select {
    & > .ant-select-arrow {
      color: var(--grey-1);
    }
    & > .ant-select-selector {
      background-color: var(--dark-blue-3);
      border-color: var(--grey-4);

      &:hover {
      }

      & > .ant-select-selection-item {
        color: var(--grey-2);
      }
    }
    &:not(.ant-select-disabled):hover .ant-select-selector {
      border-color: var(--cyan-4);
    }
    &:not(.ant-select-customize-input).ant-select-disabled .ant-select-selector {
      background-color: var(--dark-blue-3);
      opacity: .4;
    }
  }
}
.ant-select-dropdown {
  background-color: var(--dark-blue-3);
  & .ant-select-item {
    color: var(--grey-1);
    margin-left: 4px;
    margin-right: 4px;
  }
  & .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: var(--dark-blue-4);
    border: 1px solid var(--cyan-5);
    border-radius: 2px;
    color: var(--cyan-3);
    font-weight: 500;
  }
  & .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: var(--dark-blue-3);
    border: 1px solid var(--cyan-4);
    border-radius: 2px;
    color: var(--grey-1);
    font-weight: 500;
  }
}