.dl-button {
  .ant-btn-default {
    text-shadow: none;
    font-weight: 600;
    font-size: 14px;

    &.primary {
      background: var(--primary-color);
      border-color: var(--primary-color);
      color: var(--grey-1);

      &:enabled:hover {
        background: var(--primary-color-hover);
        border-color: var(--primary-color-hover);
        color: var(--cyan-6);
      }

      &:disabled, &:disabled:hover {
        background: var(--primary-color-disabled);
        border-color: var(--primary-color-disabled);
        color: var(--grey-1);
        opacity: 0.8;
      }
    }

    &.secondary {
      background: var(--secondary-color);
      border-color: var(--secondary-color);
      color: var(--dark-blue-3);

      &:enabled:hover {
        background: var(--secondary-color-hover);
        border-color: var(--secondary-color-hover);
        color: var(--grey-1);
      }

      &:disabled, &:disabled:hover {
        background: var(--secondary-color-disabled);
        border-color: var(--secondary-color-disabled);
        color: var(--grey-1);
        opacity: 0.8;
      }
    }
  }

  .ant-btn-ghost {
    background: none;
    text-shadow: none;
    font-weight: 600;
    font-size: 14px;
    color: var(--grey-1);

    &.primary {
      border: 2px solid var(--primary-color-ghost);

      &:enabled:hover {
        background: var(--primary-color-ghost);
        border-color: var(--primary-color-ghost);
        color: var(--grey-1);
      }

      &:disabled, &:disabled:hover {
        background: none;
        border-color: var(--primary-color-ghost-disabled);
        color: var(--primary-color-ghost-disabled);
        opacity: 0.6;
      }
    }

    &.secondary {
      border: 2px solid var(--secondary-color-ghost);

      &:enabled:hover {
        background: var(--secondary-color-ghost);
        border-color: var(--secondary-color-ghost);
        color: var(--dark-blue-3);
      }

      &:disabled, &:disabled:hover {
        background: var(--secondary-color-ghost-disabled);
        border-color: var(--secondary-color-ghost-disabled);
        color: var(--grey-1);
        opacity: 0.6;
      }
    }
  }

  .ant-btn-link {
    text-shadow: none;
    font-weight: 600;
    font-size: 14px;
    color: var(--secondary-color);
    padding-left: 0;
    padding-right: 0;
    white-space: normal;
    text-align: left;
    > span {
      text-decoration: underline;
    }

    &:enabled:hover {
      color: var(--primary-color-hover);
    }

    &:disabled, &:disabled:hover {
      color: var(--secondary-color);
      opacity: 0.6;
    }
  }

  .only-icon {
    &.ant-btn-default {
      padding: 4px 0 8px 8px;
    }
    &.ant-btn-ghost {
      padding: 4px 0 7px 7px;
    }
  }

  .ant-btn-lg {
    height: auto;
    padding: 12px 20px;
  }

  .ant-btn-round {
    @media (max-width: 768px) {
      min-height: 48px;
      border-radius: 0;
    }
  }

  sup {
    display: inline-block;
    border-bottom: 1px solid #fff;
    padding-bottom: 10px;
  }
}