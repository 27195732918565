.dl-radio {
  $self: &;
  .ant-radio-wrapper > .ant-radio {
    & > .ant-radio-inner {
      border: 2px solid var(--primary-color-hover);
    }

    &:hover {
      & > .ant-radio-inner {
        border: 2px solid var(--primary-color)
      }
    }

    &.ant-radio-disabled {
      & > .ant-radio-inner {
        opacity: 0.64;
        background-color: var(--grey-1);
        border: 2px solid var(--primary-color-disabled);
      }
    }
  }

  span &__label {
    color: var(--grey-1);
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
  }

  .ant-radio-wrapper-disabled {
    span #{ $self }__label {
      opacity: 0.64;
    }
  }
}