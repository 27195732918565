.dl-bottom-bar {
  display: none;
  bottom: 0;
  position: fixed;
  width: 100%;
  background: var(--dark-blue-3);
  z-index: 2;
  height: 56px;
  justify-content: flex-end;
  padding: 0 32px;
  align-items: center;
  border-top: var(--grey-5) solid 1px;
  & > .dl-button {
    margin-left: 12px
  }
}

.dl-backdrop {
  display: none;
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: linear-gradient(
                  346.38deg,
                  rgba(4, 0, 32, 0) 29.42%,
                  rgba(13, 31, 80, 0.492297) 68.99%,
                  rgba(23, 62, 129, .7) 89.18%,
                  rgba(72, 137, 215, .5) 99.22%) rgba(15, 16, 45, .9);
  z-index: 3;
  //opacity: 0.9;
  overscroll-behavior: contain;
  overflow: hidden;
  justify-content: center;
  align-items: center;

  &-content {
    opacity: 1;
    background: var(--dark-blue-3);
    border-radius: 4px;
    z-index: 4;
    padding: 32px 80px;
    color: var(--grey-2);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    span {
      margin-top: 16px;
      font-family: 'Montserrat', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      text-align: center;
      color: var(--grey-2);
    }
    &:before {
      content: ' '
    }
  }
}

.visible {
  display: flex;
}