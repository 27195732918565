.dl-card-footer {
  width: 100%;
  background-color: var(--dark-blue-3);
  border-radius: 4px;
  margin-bottom: 16px;

  &__body {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    align-items: center;
    @media (max-width: 768px) {
      flex-direction: column;
      padding: 16px;
    }

    img {
      border-radius: 4px;
      @media (max-width: 768px) {
       display: none;
      }
    }

    h4 {
      margin-left: 53px;
      color: var(--grey-1);
      font-family: 'Questrial', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      @media (max-width: 768px) {
        margin-left: 0;
      }
    }

    &-button {
      margin-right: 44px;
      margin-left: 44px;
    }
  }
}