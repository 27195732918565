.dl-card-account {
  width: 100%;
  background-color: var(--dark-blue-3);
  padding-top: 20px;
  padding-bottom: 20px;
  border-radius: 4px;
  margin-bottom: 16px;

  h4 {
    color: var(--grey-1);
    font-family: 'Questrial', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
  }

  &__title {
    padding-bottom: 16px;
    padding-left: 32px;
    padding-right: 32px;
    border-bottom: 1px solid #03021D;
  }

  &__body {
    padding-top: 20px;
    padding-left: 32px;
    padding-right: 32px;

    &-right-panel {
      & > h4 {
        margin-bottom: 40px;
      }
      &-select {
        h5 {
          margin-bottom: 16px;
          font-family: 'Montserrat', sans-serif;
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          color: var(--grey-2);
        }

        &-status {
          display: flex;
          margin-top: 16px;
          &-icon {
            font-family: 'Montserrat', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            color: var(--grey-2);
            margin-right: 24px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            & > #fraud {
              width: 8px;
              height: 8px;
              border-radius: 8px;
              margin-right: 8px;
              background: #CF1322;
            }

            & > #non-fraud {
              width: 8px;
              height: 8px;
              border-radius: 8px;
              margin-right: 8px;
              background: var(--cyan-4);
            }
          }
        }
      }
    }
  }
}